const API =
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyCcw-7xTblpeYOTSRao5M9k3SiLtLyHHd8';
const MarkerCluserAPI =
  'https://unpkg.com/@google/markerclustererplus@4.0.1/dist/markerclustererplus.min.js';
const googleMapsSelector = '.js-googlemap';
const markers = [];
const showTextInput = document.querySelector('.js-show-text');

const customPin = {
  url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(`
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="35" viewBox="0 0 300 435" fill="none">
      <circle cx="150" cy="150" r="150" fill="#006390" transform="scale(1)" />
      <path d="M150 435L23.5603 230.25L276.44 230.25L150 435Z" fill="#006390" transform="scale(1)" />
      <path d="M104.51 166.18C118.926 158.489 131.54 147.815 141.51 134.87C135.473 125.328 130.734 115.024 127.42 104.23C120.429 118.547 109.56 130.615 96.0507 139.061C82.5412 147.508 66.9327 151.994 51 152.01V180.35C73.532 180.387 95.1306 189.354 111.063 205.287C126.996 221.219 135.963 242.818 136 265.35H164.34C164.38 222.09 140.3 185.35 104.51 166.18ZM195.92 166.18C181.5 173.866 168.886 184.54 158.92 197.49C164.954 207.032 169.689 217.337 173 228.13C179.992 213.814 190.861 201.747 204.37 193.3C217.88 184.854 233.488 180.367 249.42 180.35V152C226.886 151.968 205.285 143.003 189.351 127.069C173.417 111.135 164.452 89.5337 164.42 67H136C136 110.26 160.13 147.01 195.92 166.18Z" fill="#34E0A1" transform="scale(1)" />
    </svg>
  `),
};

const customHighlightedPin = {
  url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(`
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="35" viewBox="0 0 300 435" fill="none">
      <circle cx="150" cy="150" r="150" fill="#34E0A1" transform="scale(1)" />
      <path d="M150 435L23.5603 230.25L276.44 230.25L150 435Z" fill="#34E0A1" transform="scale(1)" />
      <path d="M104.51 166.18C118.926 158.489 131.54 147.815 141.51 134.87C135.473 125.328 130.734 115.024 127.42 104.23C120.429 118.547 109.56 130.615 96.0507 139.061C82.5412 147.508 66.9327 151.994 51 152.01V180.35C73.532 180.387 95.1306 189.354 111.063 205.287C126.996 221.219 135.963 242.818 136 265.35H164.34C164.38 222.09 140.3 185.35 104.51 166.18ZM195.92 166.18C181.5 173.866 168.886 184.54 158.92 197.49C164.954 207.032 169.689 217.337 173 228.13C179.992 213.814 190.861 201.747 204.37 193.3C217.88 184.854 233.488 180.367 249.42 180.35V152C226.886 151.968 205.285 143.003 189.351 127.069C173.417 111.135 164.452 89.5337 164.42 67H136C136 110.26 160.13 147.01 195.92 166.18Z" fill="white" transform="scale(1)" />
    </svg>
  `),
};

export const init = () => {
  const googleMaps = [...document.querySelectorAll(googleMapsSelector)];
  if (googleMaps.length) {
    const timeoutTime = 1250;
    loadAPI();
    setTimeout(function () {
      initMap();
    }, timeoutTime);
  }
};

const loadAPI = () => {
  if (
    typeof google == 'undefined' ||
    typeof google.maps == 'undefined' ||
    typeof google.maps.Map == 'undefined'
  ) {
    var tag = document.createElement('script');
    tag.src = API;
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    var clusterTag = document.createElement('script');
    clusterTag.src = MarkerCluserAPI;
    firstScriptTag.parentNode.insertBefore(clusterTag, firstScriptTag);
  }
};

const initMap = () => {
  const googleMaps = [...document.querySelectorAll(googleMapsSelector)];

  if (googleMaps.length) {
    for (var i = 0; i < googleMaps.length; i++) {
      var googleMap = googleMaps[i];

      var uluru = {
        lat: parseFloat(googleMap.dataset.lat),
        lng: parseFloat(googleMap.dataset.lng),
      };

      var infowindow = new google.maps.InfoWindow();

      var extraMarkers = [
        ...googleMap.querySelectorAll('.js-marker-locations'),
      ];

      var map = new google.maps.Map(googleMap.querySelector('.js-map'), {
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP
        },
        center: uluru,
        mapTypeControl: false,
        streetViewControl: false,
        zoom: parseInt(googleMap.dataset.zoom),
        maxZoom: 18,
        mapTypeControl: false,
        streetViewControl: false,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP
        },
        styles: getStyles()
      });


      if (extraMarkers.length) {
        const markerLocations = [];

        markerLocations.push({
          lat: parseFloat(googleMap.dataset.lat),
          lng: parseFloat(googleMap.dataset.lng),
          title: googleMap.dataset.title,
          url: googleMap.dataset.url,
          phone: googleMap.dataset.phone,
          description: googleMap.dataset.description,
          highLighted: true
        })
        for (var i = 0; i < extraMarkers.length; i++) {
      
          markerLocations.push({
            lat: parseFloat(extraMarkers[i].dataset.lat),
            lng: parseFloat(extraMarkers[i].dataset.lng),
            title: extraMarkers[i].dataset.title,
            url: extraMarkers[i].dataset.url,
            phone: extraMarkers[i].dataset.phone,
            description: extraMarkers[i].dataset.description,
            highLighted: false
          });
        }

        for (var j = 0; j < markerLocations.length; j++) {
          placeMarker(map, infowindow, markerLocations[j]);
        }

        var clusterOptions = {
          imagePath: "/Static/images/markers/icon-marker",
          gridSize: 36,
          maxZoom: 18
        }

        new MarkerClusterer(map, markers, clusterOptions);
      }
    }
  }
};

function placeMarker(map, infowindow, loc) {
  const marker = new google.maps.Marker({
    position: { lat: loc.lat, lng: loc.lng },
    map: map,
    title: loc.title,
    icon: loc.highLighted ? customHighlightedPin : customPin
  });
  markers.push(marker);
  if (loc.title) {
    google.maps.event.addListener(marker, 'click', function () {
      infowindow.close();
      infowindow.setContent(
        `<div class="googlemaps__infowindow"><h3 class="text-medium-blue text-xl">${loc.title}</h3>
    <div class="d-flex justify-content-between">
        <div>
          <p class="text-base"><span class="d-block mb-3">${loc.description}</span>
              <span class="d-block mb-2"> ${loc.phone}</span>
            </p></div>
        <div class="mt-auto pb-3">
            <a href="${loc.url}" class="button button-reset button-googlemap">${showTextInput.value}</a>
        </div></div>  </div>`,
      );
      infowindow.open(map, marker);
      setTimeout(function(e) {
        
        var btn = document.querySelector('.gm-ui-hover-effect');
        var span = document.createElement('span');
        var span2 = document.createElement('span');
        btn.classList.add('googlemaps__close');
        btn.innerText = '';
        btn.appendChild(span);
        btn.appendChild(span2);
      }, 1);
    });
  }
}

function getStyles() {
  return [
    {
      "featureType": "administrative",
      "elementType": "all",
      "stylers": [{ "visibility": "off" }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [{ "visibility": "off" }]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [{ "color": "#d0d0d0" }, { "lightness": 17 }]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [{ "color": "#d0d0d0" }, { "lightness": 29 }, { "weight": 0.2 }]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [{ "color": "#d0d0d0" }, { "lightness": 18 }]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [{ "color": "#d0d0d0" }, { "lightness": 16 }]
    },
    {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [{ "color": "#fff" }],
    },
    {
      "elementType": "labels.icon",
      "stylers": [{ "visibility": "off" }]
    },
    {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [{ "hue": "#727D82" }, { "lightness": -30 }, { "saturation": -80 }]
    },
    {
      "featureType": "water",
      "elementType": "all",
      "stylers": [{ "visibility": "simplified" }, { "color": "#e1e1e1" }]
    }
  ]
}
